<template>
    <div class="bg">
        <h1 class="title">Quality<br>And<br>Uniqueness</h1>
        <img class="logo" src="../../assets/images/fi-logo-hotel.svg" alt="Firm Industries">
    </div>
    <div class="container custom-container">
        <div class="row">
            <div class="col-12 mb-5">
                <p class="subtitle mb-3 mw-80">
                    We are the Trading and procurement arm of the Group based in Ras Al Khaimah, UAE. 
                    Over the years we have become recognized as the leading suppliers & distributors for international brands within the region and have built close relations with them. Internally we are also able to work closely with a number of Public & Private Institutions.
                </p>
                <p class="subtitle">
                    Our Mission is to develop upcoming markets in the MENA region by providing their markets with highly competitive and attractive products and services.
                </p>
            </div>

            <div class="col-12 col-md-6 mb-5">
                <h5 class="mb-3">Your Partner in Beauty and Cosmetics</h5>
                <p class="subtitle mb-3">
                    The company has grown to become a leading distribution company for our region. With a variety of products such as Pharmaceutical products, Medical equipment, Fast Moving Consumer Goods as well as hospitality products catering to 3&5 star hotels in the region.
                </p>
                <p class="subtitle">
                    Throughout our 15 years of experience we have developed long term partnerships with various International Medical companies and we are in a position to supply a wide variety of products throughout the region, while also being involved with well known brand owners to enable us to handle the fast growing market demands.
                </p>
            </div>

            <div class="col-12 col-md-6 mb-5">
                <h5>Our Team</h5>
                <p class="subtitle">
                    We are convinced that our team is our greatest strength. Our team is responsible for making sure that all processes run smoothly and to make sure that our business partners can rely on our services. This is why we build our team of highly engaged, responsible and professional employees. Our employees are aware of their tasks and responsibilities to make sure that our customers are always served with the optimal services and best prices while maintaining the best possible service level. Convince yourself now and get to know our team.
                </p>
            </div>
        </div>
    </div>
    <img class="bottom-bg" src="../../assets/images/hotel-btm-bg.svg" alt="bottom background">
</template>

<script>
export default {
    name: 'Consumable',
    mounted() {
        document.body.style.backgroundColor = '#DCDCDD';
    },
}
</script>

<style scoped>
div.bg {
    background: url('../../assets/images/hotel-bg.png') no-repeat center;
    background-size: cover;
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: -1;
    border-bottom: 5px #D0B78A solid;
}

.container.custom-container {
    margin: 0 10px !important;
    max-width: calc(100% - 20px) !important;
}

h1.title {
    text-transform: uppercase;
    position: absolute;
    font-family: Montserrat-Bold;
    color: #F2F2F3;
    margin-bottom: 20px;
    padding: 0 35px 0;
    font-size: 5vw;
    bottom: 0;
}

.container {
    padding-top: calc(100vh + 30px);
}

img.logo {
    position: absolute;
    z-index: 5;
    height: 150px;
    bottom: -95px;
    right: 0;
}

p.subtitle.mw-80 {
    max-width: calc(100% - 80px) !important;
}

p.subtitle {
    font-family: Montserrat-Light;
    color: #4A4B58;
}

h5 {
    font-family: Montserrat-Bold;
    color: #4A4B58;
}

img.bottom-bg {
    opacity: 0.7;
    position: absolute;
    mix-blend-mode: lighten;
    top: 100vh;
    left: 0;
    z-index: -2;
    height: 800px;
}

@media only screen and (max-width: 768px) {
    h1.title {
        font-size: 10vw;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    h1.title {
        padding: 0 20px 0;
    }
}
</style>